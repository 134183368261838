import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/components/background-decorations/dot-grid.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/components/background-decorations/wave.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/components/footer/footer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/components/logo/zws.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/components/trpc/trpc-provider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/components/background-decorations/light-spot.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@highlight-run/next/dist/next-client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Lato\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}")